import React, { useState } from "react";
import { Box, Modal, Typography, ThemeProvider } from "@mui/material";
import { theme } from '../../settings/theme'
import variables from '../../settings/_variables.scss'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '76%',
        sm: '60%',
        md: '60%',
        lg: '42%',
        xg: '28%'
    },
    bgcolor: variables.WHITE1,
    boxShadow: 10,
    p: 4,
    borderRadius: '65px'
}

const InfoModal = ({handleClick, totalCandidates}) => {
  const [open, setOpen] = useState(true);

  // Function to handle modal state
  const toggleModal = (isOpen) => {
    setOpen(isOpen);
    if (!isOpen) {
        handleClick()
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {/* Modal */}
      <Modal
        open={open}
        onClose={() => toggleModal(false)}
        aria-labelledby="info-modal-title"
        aria-describedby="info-modal-description"
        slotProps={{
            backdrop: {
                sx: {
                    background: variables.CYAN,
                    opacity: '0.7 !important'
                },
                timeout: 500
            }
        }}
      >
        <Box
            sx={{
                maxHeight: '500px',
                display: 'block',
                ...style
            }}
        >
            <Box sx={{ margin: 'auto', textAlign: 'center', alignItems: 'center' }}>
                <Typography sx={{ mt: 3 }}>
                    Emails sent successfully. Candidates will receive them shortly.
                </Typography>
                <Box
                    sx={{
                        mt: 2,
                        color: '#fff',
                        textAlign: 'center',
                        cursor: 'pointer',
                        display: 'inline-block',
                        borderRadius: '40px',
                        padding: '14px',
                        fontSize: '16px',
                        boxShadow: 'none',
                        fontFamily: "Poppins Black",
                        letterSpacing: '0px',
                        backgroundColor: 'rgb(0, 45, 89)',
                        textTransform: 'none',
                        marginRight: '16px',
                        paddingTop: '16px',
                        paddingBottom: '16px',
                        paddingLeft: '32px',
                        paddingRight: '32px',
                    }}
                    onClick={() => toggleModal(false)}
                >
                    OK
                </Box>
            </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default InfoModal;
