import { gql, useQuery } from '@apollo/client'
import { Box, CircularProgress, Container } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import DashboardJobsContainer from '../../views/Dashboard/DashboardJobsContainer'
import variables from '../../settings/_variables.scss'
import LoadMoreLink from '../elements/LoadMoreLink'

const GET_LIKED_JOBS_QUERY = gql`
    query GetCandidateLikedJobs($after: String, $id: ID!) {
        getCandidateLikedJobs(first: 3, after: $after, id: $id) {
            edges {
                node {
                    id
                    jobPost {
                        id
                        jobTitle
                        companyName
                        experienceRequired
                        companyLogoUrl
                        otherDepartment
                        otherIndustry
                        status
                        isActive
                        industries {
                            id
                            name
                        }
                        states {
                            id
                            name
                        }
                        departments {
                            id
                            name
                        }
                    }
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
        }
    }
`

const CandidateLikedJobsList = props => {
    const { loginCheckAuthDetails, loginDetails, isUserAuthenticated } = useSelector(
        state => state.login
    )
    const [listLoader, setListLoader] = useState(false)
    const { loading, data, fetchMore } = useQuery(GET_LIKED_JOBS_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            id: loginCheckAuthDetails.id ? loginCheckAuthDetails.id : loginDetails.id
        },
        onCompleted() {
            setListLoader(false)
        }
    })
    if (loading)
        return (
            <Box
                sx={{
                    textAlign: 'center'
                }}
            >
                <CircularProgress
                    sx={{
                        color: variables.BLUE
                    }}
                />
            </Box>
        )
    const likedJobs = data && data.getCandidateLikedJobs.edges.map(edge => edge.node.jobPost)

    const pageInfo = data && data.getCandidateLikedJobs.pageInfo
    return (
        <Container maxWidth='xl' disableGutters>
            <Box sx={{ mb: 5 }}>
                {likedJobs && likedJobs.length > 0
                    ? likedJobs.map(job => (
                          <DashboardJobsContainer
                              jobData={job}
                              listType='likedJobs'
                              refetchAppliedJobCount={props.refetchAppliedJobCount}
                          />
                      ))
                    : null}
                {isUserAuthenticated && pageInfo && pageInfo.hasNextPage ? (
                    listLoader ? (
                        <Box
                            sx={{
                                textAlign: 'center'
                            }}
                        >
                            <CircularProgress
                                size={20}
                                sx={{
                                    height: '20px',
                                    width: '20px',
                                    color: variables.BLUE
                                }}
                            />
                        </Box>
                    ) : (
                        <LoadMoreLink
                            onClick={() => {
                                if (pageInfo.hasNextPage) {
                                    setListLoader(true)
                                    fetchMore({
                                        variables: {
                                            after: pageInfo.endCursor ? pageInfo.endCursor : ''
                                        }
                                    })
                                }
                            }}
                        />
                    )
                ) : null}
            </Box>
        </Container>
    )
}

export default CandidateLikedJobsList
