import { Dialog, DialogActions, DialogContent, DialogTitle, Button, List, ListItem, ListItemText, ThemeProvider, Box, IconButton, Divider } from '@mui/material';
import React, { useState } from 'react'
import { theme } from '../../settings/theme'
import variables from '../../settings/_variables.scss'
import GroupIcon from '@mui/icons-material/Group';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '90%',
        sm: '60%',
        md: '60%',
        lg: '50%'
    },
    height: '500px',
    bgcolor: variables.WHITE1,
    boxShadow: 10,
    p: 4,
    borderRadius: '65px'
}

const SelectedUserList = ({ open, onClose, candidateList, selectedUsersData }) => {
    // const { open, onClose, candidateList, selectedUsersData } = props

    const filterSelectedUsers = (selectedUsersData) => {
        return Object.fromEntries(
            Object.entries(selectedUsersData).filter(([key, value]) => value !== false)
        );
    };

    // Function to find users based on ids
    const findSelectedUsers = (filteredData) => {
        return Object.keys(filteredData).map(id => {
            const candidate = candidateList.find(candidate => candidate.userId === parseInt(id, 10));
            return candidate
        });
    };

    // Step 1: Filter out the 'false' values from selectedUsersData
    const filteredUsersData = filterSelectedUsers(selectedUsersData);

    // Step 2: Use the filtered data to find selected candidates
    const filteredUsers = findSelectedUsers(filteredUsersData);

    const sortedUsers = [...filteredUsers].sort((a, b) => b.userId - a.userId);

    // Initialize a counter to number the list items
    let number = 1;

    return (

        <ThemeProvider theme={theme}>
            <Dialog maxWidth="sm" fullWidth sx={{
                padding: '20px',
                '& .MuiDialog-paper': {
                    backgroundColor: 'rgb(255, 250, 244)',
                    borderRadius: '65px',
                },
                '& .MuiDialogPaper-root': {
                    padding: '16px',
                    '@media (max-width:600px)': {
                        width: '90%',
                        margin: 0,
                    },
                    '@media (min-width:600px) and (max-width:960px)': {
                        width: '80%',
                    },
                    '@media (min-width:960px)': {
                        width: '50%',
                    },
                }
            }} open={open} onClose={onClose}>
                <DialogTitle variant='h2' className='modal-heading'>
                    <IconButton edge="start" color="inherit" aria-label="users" sx={{ mr: 2 }}>
                        <GroupIcon sx={{fontSize: 40, marginLeft: 2}}/>
                    </IconButton>
                    Users List
                </DialogTitle>
                <DialogContent>
                    {/* List of users */}
                    <List style={{ maxHeight: 300, overflowY: 'auto' }} sx={{ padding: 0, backgroundColor: '#f5f5f5', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
                        {Object.entries(sortedUsers).map(([key, user]) => (
                            <React.Fragment key={key}>
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <>
                                                <span>
                                                    <strong style={{ fontSize: '1rem' }}>{number++}. </strong>
                                                    <span style={{ fontSize: "18px"}}>{user.name}</span>
                                                </span>
                                            </>
                                        }
                                        primaryTypographyProps={{
                                        variant: 'h6',
                                        color: 'rgb(0, 45, 89)',  // Custom color for text
                                        }}
                                    />
                                </ListItem>
                                {/* Add Divider between items except after the last one */}
                                {number <= Object.entries(sortedUsers).length && <Divider />}
                            </React.Fragment>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    {/* Button to close the dialog */}
                    <Box sx={{ textAlign: 'center', marginTop: {xs: "9px", sm: "9px", md: "9px", lg: "-15px0px", xl: "24px"}, marginBottom: {lg: "14px"} }}>
                        <Button color='primary' variant='contained' size='large' sx={{ mr: 2, py: 2, px: 4 }} onClick={onClose}>
                            Close
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}

export default SelectedUserList
