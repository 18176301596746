/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Button } from '../../components'
import {
    Box,
    CircularProgress,
    Container,
    FormHelperText,
    IconButton,
    InputAdornment,
    Link as MuiLink,
    Typography
} from '@mui/material'
import './styles.scss'
import { useForm } from 'react-hook-form'
import { ErrorMessage, keys, Placeholder, Regex, TextStrings } from '../../constants'
import { loginCheckAuth, loginFailure, loginSuccess } from '../../redux/actions/login'
import { useDispatch, useSelector } from 'react-redux'
import variables from '../../settings/_variables.scss'
import LOGO_WITH_TEXT from '../../assets/icons/LOGO_WITH_TEXT.svg'
import LinkedinLogo from '../../assets/icons/linkedin.svg'
import GoogleLogo from '../../assets/icons/google.svg'
import Icons from '../../components/Icons'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import PageMetaTags from '../../base/PageMetaTags'
import { gql, useMutation, useQuery } from '@apollo/client'
import Cookies from 'universal-cookie'
import { styles } from './styles'
import { LoginSocialGoogle, LoginSocialLinkedin } from 'reactjs-social-login'
import { useCallback, useEffect, useState } from 'react'
import { LOGIN_MUTATION } from '../../gql/queries'
import { StyledSocialLoginButton } from '../../base/commonStyles'
import { updateProfileDataFromDB } from '../../redux/actions/profileDetail'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { clarity } from 'react-microsoft-clarity'
import ControlledTextInput from '../../components/molecules/ControlledTextInput'
import { useLinkedIn } from 'react-linkedin-login-oauth2'

const cookies = new Cookies()

const currentUser = gql`
    query UserStore {
        currentUser {
            id
            name
            firstName
            lastName
            name
            email
            userRole
            mobileNumber
            profileImage
            candidate {
                id
                language
                hasResume
            }
            company {
                id
                companyLogoUrl
            }
        }
        experienceDetails {
            id
            jobTitle
            startDate
            endDate
            departmentId
            companyName
            industryId
            candidate {
                id
                language
            }
            department {
                id
                name
            }
            industry {
                id
                name
            }
        }
        educationDetails {
            id
            certificateDegreeName
            yearOfCompletion
            major
            instituteName
            startDate
            endDate
        }
    }
`

const ForgotPasswordLink = props => <RouterLink to='/forgot-password' {...props} />

// const REDIRECT_URI = 'https://brave-sand-0862bc103.2.azurestaticapps.net/login'
// const REDIRECT_URI = `${process.env.REACT_APP_SOCIAL_LOGIN_REDIRECT_URI}/
const Login = props => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const [apiError, setApiError] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [linkedinLoading, setLinkedinLoading] = useState(false)
    const [googleLoading, setGoogleLoading] = useState(false)
    const [authCode, setAuthCode] = useState(null);

    const REDIRECT_URI = window.location.href

    const handleClickShowPassword = () => setShowPassword(show => !show)

    const handleMouseDownPassword = event => {
        event.preventDefault()
    }

    useEffect(() => {
        const listener = event => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                handleSubmit(onSubmit)()
                event.preventDefault()
            }
        }
        document.addEventListener('keydown', listener)
        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [])

    const [loginMutation, { data, error, loading, reset }] = useMutation(LOGIN_MUTATION, {
        onCompleted(data) {
            dispatch(loginSuccess(data))
            cookies.set('BUNTON_ACCESS_TOKEN', data.signIn.accessToken, { path: '/' })
            cookies.set('BUNTON_CLIENT_TOKEN', data.signIn.client, { path: '/' })
            cookies.set('BUNTON_EXPIRY', data.signIn.expiry, { path: '/' })
            cookies.set('BUNTON_UID', data.signIn.uid, { path: '/' })

            refetch()
            handleRedirect(data.signIn.role)
        },
        onError(error) {
            setApiError(error)
            dispatch(loginFailure(error.message))
        }
    })

    const { refetch } = useQuery(currentUser, {
        fetchPolicy: 'network-only',
        onCompleted(data) {
            if (data.currentUser && data.currentUser.id) {
                clarity.identify(data.currentUser.id, {
                    name: data.currentUser.name,
                    email: data.currentUser.email,
                    userRole: data.currentUser.userRole
                })
            }
            dispatch(loginCheckAuth(data))
            // //saving education data in redux
            let educationData = []
            educationData['key'] = keys.education
            educationData['data'] = data.educationDetails ? data.educationDetails : []
            dispatch(updateProfileDataFromDB(educationData))

            // //saving experience data in redux
            let experienceData = []
            experienceData['key'] = keys.workExperience
            experienceData['data'] = data.experienceDetails ? data.experienceDetails : []
            dispatch(updateProfileDataFromDB(experienceData))

            // //saving language data in redux
            if (data?.currentUser?.candidate?.language) {
                let languageData = []
                languageData['key'] = keys.languages
                languageData['data'] = [{ language: data?.currentUser?.candidate?.language }]
                dispatch(updateProfileDataFromDB(languageData))
            }

            // //saving avatar data in redux
            if (data?.currentUser?.profileImage) {
                let avatarData = []
                avatarData['key'] = keys.selectAnAvatar
                avatarData['data'] = [{ profileImage: data?.currentUser?.profileImage }]
                dispatch(updateProfileDataFromDB(avatarData))
            }

            // //saving additional indo data in redux

            if (
                data?.currentUser?.candidate?.about ||
                (data?.currentUser?.candidate?.benefits &&
                    data?.currentUser?.candidate?.benefits.length > 0)
            ) {
                let avatarData = []
                avatarData['key'] = keys.additionalInfos
                avatarData['data'] = [
                    {
                        about: data?.currentUser?.candidate?.about,
                        benefits: data?.currentUser?.candidate?.benefits
                    }
                ]
                dispatch(updateProfileDataFromDB(avatarData))
            }
        },
        onError(error) {
            dispatch(loginFailure(error.message))
        }
    })

    const handleRedirect = role => {
        switch (role) {
            case 'user':
                navigate('/dashboard')
                break
            case 'admin':
                navigate('/admin-portal')
                break
            default:
                navigate('/dashboard')
                break
        }
    }

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues: {
            email: '',
            password: ''
        }
    })
    const onSubmit = data => {
        setApiError('')
        data['provider'] = 'normal'
        data['accessToken'] = null
        loginMutation({
            variables: {
                ...data,
                loginLocation: 'login'
            }
        })
    }

    const onLoginStart = useCallback(() => {
        setLinkedinLoading(true)
        // alert('login start')
    }, [])

    const { linkedInLogin } = useLinkedIn({
        clientId: process.env.REACT_APP_LINKEDIN_LOGIN_CLIENT_ID,
        redirectUri: window.location.origin + process.env.REACT_APP_LINKEDIN_LOGIN_REDIRECT_URI,
        scope:'r_liteprofile,r_emailaddress',
        onSuccess: (code) => {
            let loginData = {}
            loginData['provider'] = 'linkedin'
            loginData['accessToken'] = code
            setGoogleLoading(false)
            loginMutation({
                variables: {
                    ...loginData,
                    loginLocation: 'login',
                    referrerSite: window.location.origin
                }
            })
        },
        onError: (error) => {
          console.log(error);
        },
      });

    return (
        <Container sx={{ py: 4, height: '100%' }}>
            <PageMetaTags title='Login | Bunton' />
            <Box maxWidth='xl' sx={{ m: 'auto' }}>
                <Box sx={{ textAlign: 'center' }}>
                    <RouterLink to='/'>
                        <Box
                            component='img'
                            src={LOGO_WITH_TEXT}
                            sx={
                                responsiveBreakpoint === 'mob'
                                    ? {
                                          width: '150px'
                                      }
                                    : styles.logo
                            }
                        />
                    </RouterLink>
                </Box>

                <Box sx={{ mt: 6, px: { xs: 0, md: 10 }, py: { xs: 5, md: 10 } }}>
                    <Box
                        sx={{
                            mb: 8,
                            display: {
                                xs: 'block',
                                sm: 'flex'
                            },
                            justifyContent: 'center'
                        }}
                    >
                        <Box sx={{ textAlign: 'center' }}>
                            <StyledSocialLoginButton
                                label={
                                    <Typography sx={{ fontSize: '15px' }}>
                                        {TextStrings.loginWithLinkedin}
                                    </Typography>
                                }
                                color='secondary'
                                variant='contained'
                                size='large'
                                onClick={linkedInLogin}
                                sx={{
                                    marginRight: {
                                        xs: '0px',
                                        sm: '15px',
                                        md: '15px',
                                        lg: '15px',
                                        xl: '15px'
                                    },
                                    marginBottom: {
                                        xs: '15px',
                                        sm: '0px',
                                        md: '0px',
                                        lg: '0px',
                                        xl: '0px'
                                    },

                                    // width: 'max-content'
                                    width: '240px',
                                    height: '56px'
                                }}
                                classes='social_login'
                                disabled={linkedinLoading}
                                startIcon={
                                    <Icons
                                        src={LinkedinLogo}
                                        sx={{
                                            height: '20px',
                                            width: '19.56px',
                                            marginTop: '-5px'
                                        }}
                                    />
                                }
                            />
                            {/* <LoginSocialLinkedin
                                isOnlyGetToken
                                client_id={process.env.REACT_APP_LINKEDIN_LOGIN_CLIENT_ID}
                                client_secret={process.env.REACT_APP_LINKEDIN_LOGIN_CLIENT_SECRET}
                                redirect_uri={REDIRECT_URI}
                                scope={'r_liteprofile%20r_emailaddress'}
                                onLoginStart={() =>{
                                    setLinkedinLoading(true)
                                }}
                                onResolve={({ provider, data }) => {
                                    if (data) {
                                        let loginData = {}
                                        loginData['provider'] = provider
                                        loginData['accessToken'] = data.access_token
                                        setLinkedinLoading(false)
                                        loginMutation({
                                            variables: {
                                                ...loginData,
                                                loginLocation: 'login'
                                            }
                                        })
                                    }
                                }}
                                onReject={err => {
                                    setLinkedinLoading(false)
                                    dispatch(loginFailure(err.message))
                                }}
                            >
                                <StyledSocialLoginButton
                                    label={
                                        <Typography sx={{ fontSize: '15px' }}>
                                            {TextStrings.loginWithLinkedin}
                                        </Typography>
                                    }
                                    color='secondary'
                                    variant='contained'
                                    size='large'
                                    onClick={e => {
                                        e.preventDefault()
                                        setLinkedinLoading(true)
                                    }}
                                    sx={{
                                        marginRight: {
                                            xs: '0px',
                                            sm: '15px',
                                            md: '15px',
                                            lg: '15px',
                                            xl: '15px'
                                        },
                                        marginBottom: {
                                            xs: '15px',
                                            sm: '0px',
                                            md: '0px',
                                            lg: '0px',
                                            xl: '0px'
                                        },

                                        // width: 'max-content'
                                        width: '240px',
                                        height: '56px'
                                    }}
                                    classes='social_login'
                                    disabled={linkedinLoading}
                                    startIcon={
                                        <Icons
                                            src={LinkedinLogo}
                                            sx={{
                                                height: '20px',
                                                width: '19.56px',
                                                marginTop: '-5px'
                                            }}
                                        />
                                    }
                                />
                            </LoginSocialLinkedin> */}
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                            <LoginSocialGoogle
                                client_id={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}
                                onLoginStart={onLoginStart}
                                redirect_uri={REDIRECT_URI}
                                scope='openid profile email'
                                discoveryDocs='claims_supported'
                                access_type='offline'
                                onResolve={({ provider, data }) => {
                                    if (data) {
                                        let loginData = {}
                                        loginData['provider'] = provider
                                        loginData['accessToken'] = data.access_token
                                        loginData['email'] = data.email
                                        setGoogleLoading(false)
                                        loginMutation({
                                            variables: {
                                                ...loginData,
                                                loginLocation: 'login'
                                            }
                                        })
                                    }
                                }}
                                onReject={err => {
                                    dispatch(loginFailure(err.message))
                                }}
                            >
                                <StyledSocialLoginButton
                                    label={
                                        <Typography sx={{ fontSize: '15px' }}>
                                            {TextStrings.loginWithGoogle}
                                        </Typography>
                                    }
                                    color='secondary'
                                    variant='contained'
                                    size='large'
                                    onClick={e => {
                                        e.preventDefault()
                                        setGoogleLoading(true)
                                    }}
                                    classes='social_login'
                                    sx={{
                                        // width: 'max-content'
                                        width: '235.56px',
                                        height: '56px'
                                    }}
                                    disabled={googleLoading}
                                    startIcon={
                                        <Icons
                                            src={GoogleLogo}
                                            sx={{
                                                height: '20px',
                                                width: '19.56px',
                                                marginTop: '-5px'
                                            }}
                                        />
                                    }
                                />
                            </LoginSocialGoogle>
                        </Box>
                    </Box>

                    <Box sx={{ textAlign: 'center', width: 'fit-content', margin: 'auto' }}>
                        <Typography
                            variant='h4'
                            sx={{
                                fontSize: '18px',
                                mb: 2,
                                textAlign: 'center'
                            }}
                        >
                            {TextStrings.loginNew}
                        </Typography>
                        <Box sx={{ mb: 1 }}>
                            <ControlledTextInput
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: ErrorMessage.emptyEmail
                                    },
                                    pattern: {
                                        value: Regex.emailRegex,
                                        message: ErrorMessage.validEmail
                                    }
                                }}
                                name='email'
                                type='email'
                                placeholder={Placeholder.enterEmail}
                                label={TextStrings.email}
                                errors={errors && errors.email && errors.email.message}
                                required={true}
                                formContolSx={{width: '100%'}}
                                withoutLable={true}
                            />
                        </Box>
                        <Box>
                            <ControlledTextInput
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: ErrorMessage.emptyPassword
                                    },
                                    minLength: {
                                        value: 6,
                                        message: ErrorMessage.validPassword
                                    }
                                }}
                                type={showPassword ? 'text' : 'password'}
                                name='password'
                                placeholder={Placeholder.enterPassword}
                                label={TextStrings.password}
                                errors={errors && errors.password && errors.password.message}
                                required={true}
                                formContolSx={{width: '100%'}}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                withoutLable={true}
                            />
                        </Box>
                        {apiError && apiError?.message ? (
                            <FormHelperText error={true} id='my-helper-text' sx={{ mt: 1, mb: 1, ml: 2 }}>
                                <strong>{apiError?.message}</strong>
                            </FormHelperText>
                        ) : null}
                        <Box sx={{ mt: 1 }}>
                            <MuiLink
                                component={ForgotPasswordLink}
                                variant='body2'
                                className='forgot_passowrd'
                            >
                                {TextStrings.forgotPassword}
                            </MuiLink>
                        </Box>
                        <Box>
                            <Button
                                label={
                                    loading ? (
                                        <CircularProgress
                                            sx={{
                                                color: variables.BLUE
                                            }}
                                        />
                                    ) : (
                                        TextStrings.signIn
                                    )
                                }
                                color='primary'
                                variant='contained'
                                size='large'
                                disabled={loading}
                                // disabled={!isDirty || !isValid}
                                onClick={handleSubmit(onSubmit)}
                                type='submit'
                                sx={{
                                    paddingLeft: '50px',
                                    paddingRight: '50px',
                                    border: '5px solid #FFFAF4',
                                    boxShadow: 'none',
                                    marginTop: '42px',
                                    width: '211px'
                                }}
                                classes='sign_in'
                            />
                        </Box>

                        <Box>
                            <div className='sign_up'>
                                {TextStrings.dontHaveAcc}
                                <MuiLink
                                    component={RouterLink}
                                    variant='body2'
                                    className='sign_up_link'
                                    to='/registration'
                                    sx={{
                                        fontSize: '15px',
                                        fontWeight: '600'
                                    }}
                                >
                                    {TextStrings.signUp}
                                </MuiLink>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

export default Login
