import { Box, Chip, styled } from '@mui/material'
import React, {useState, useEffect} from 'react'
import CheckIcon from '../../assets/icons/CHECK.svg'
import variables from '../../settings/_variables.scss'
import ControlledTextInput from '../molecules/ControlledTextInput'

const StyledChip = styled(Chip)`
    padding: 16px;
    margin: 8px 16px 0 0;
    background-color: ${variables.CYAN1};
    border-color: ${variables.CYAN1};
`

const StyledCheckedBox = styled(Box)`
    height: 25px;
    width: 25px;
    border-radius: 35px;
    text-align: center;
    border: 1px solid ${variables.CYAN};
    background-color: ${variables.CYAN};
`

const StyledCheckedBoxUnchecked = styled(Box)`
    height: 25px;
    width: 25px;
    border-radius: 35px;
    text-align: center;
    border: 1px solid ${variables.CYAN};
    background-color: ${variables.WHITE1};
`

const RegistrationChipBox = props => {
    const [isOther, setIsOther] = useState(false)
    const otherFieldName = `other${props.name.charAt(0).toUpperCase() + props.name.slice(1)}`;

    useEffect(() => {
        setIsOther(props.checked && props.label === 'Others');
    }, [props.checked, props.label]);

    return (
        <>
            <StyledChip
                disabled={props.disabled}
                label={props.label}
                icon={
                    props.checked ? (
                        <StyledCheckedBox>
                            <Box
                                component='img'
                                src={CheckIcon}
                                sx={{ position: 'relative', top: '25%', height: '12px' }}
                            />
                        </StyledCheckedBox>
                    ) : (
                        // <Icons
                        //     src={CheckboxIcon}
                        //     style={{
                        //         marginRight: '0px'
                        //     }}
                        // />
                        <StyledCheckedBoxUnchecked />
                    )
                }
                variant='outlined'
                {...props}
            />
            {isOther ? (
                <Box
                sx={{
                    position: 'relative',
                    top: '-16px',
                    right: '-31px',
                    marginRight: '85px',
                    width: '100%'
                  }}
                >
                    <Box sx={{ mt: 4 }} {...props.register(otherFieldName)}>
                        <ControlledTextInput
                            control={props.control}
                            rules={{ ...props.otherFieldRules }}
                            name={otherFieldName}
                            type='text'
                            placeholder='Others'
                            errors={props.otherFieldErrors}
                            required={true}
                            fullWidth={true}
                            formContolSx={{ width: '100%' }}
                            withoutLable={true}
                            register={props.register}
                        />
                    </Box>
                </Box>
            ) : null}
        </>
    )
}

export default RegistrationChipBox
