import { gql } from "@apollo/client";

export const REGISTRATION_MUTATION = gql`
    mutation CreateUser(
        $name: String
        $password: String
        $email: String
        $industry: String
        $location: String
        $profession: String
        $department: String
        $experience: String
        $terms: Boolean
        $businessNetwork: String
        $otherDepartment: String
        $otherBusinessNetwork: String
        $otherIndustry: String
    ) {
        createUser(
            name: $name
            email: $email
            password: $password
            industry: $industry
            location: $location
            profession: $profession
            department: $department
            experience: $experience
            terms: $terms
            businessNetwork: $businessNetwork
            otherDepartment: $otherDepartment
            otherBusinessNetwork: $otherBusinessNetwork
            otherIndustry: $otherIndustry
            ) {
                firstName
                profileImage
                id
        }
    }
`

export const CURRENT_USER_QUERY = gql`
        query UserStore {
            currentUser {
                id
                name
                firstName
                lastName
                name
                email
                userRole
                mobileNumber
                profileImage
                candidate {
                    id
                    language
                }
            }
            experienceDetails {
                id
                jobTitle
                startDate
                endDate
                departmentId
                companyName
                industryId
                candidate {
                    id
                    language
                }
                department {
                    id
                    name
                }
                industry {
                    id
                    name
                }
            }
            educationDetails {
                id
                certificateDegreeName
                yearOfCompletion
                major
                instituteName
                startDate
                endDate
            }
        }
    `


export const UPDATE_MANDATORY_QUESTIONS_MUTATION = gql`
    mutation UpdateRequiredQuestions(
        $industry: String!
        $department: String!
        $experience: String!
        $location: String!
        $userId: ID!
        $otherDepartment: String
        $otherIndustry: String
        $businessNetwork: String
        $otherBusinessNetwork: String
    ) {
        updateRequiredQuestions(
            industry: $industry
            department: $department
            experience: $experience
            location: $location
            userId: $userId
            otherDepartment: $otherDepartment
            otherIndustry: $otherIndustry
            businessNetwork: $businessNetwork
            otherBusinessNetwork: $otherBusinessNetwork
        ) {
            id
        }
    }
`