import React from 'react'
import Layout from '../template/Layout'
import PageMetaTags from '../base/PageMetaTags'
import { Box, Container, Grid, Typography } from '@mui/material'
import { gql, useQuery } from '@apollo/client'
import Loader from '../components/Loader'
import JanetBanner from '../assets/images/Janet_REC_Banner_Head.png'
import JanetMobileBanner from '../assets/images/janet_rec_mobile_banner.png'
import { useSelector } from 'react-redux'
import variables from '../settings/_variables.scss'
import { addHttpsToUrl } from '../utils/commonFunctions'
import MoreJobOffers from '../components/MoreJobOffers'
import IntrestingJobs from '../components/IntrestingJobs'

const JANET_RECOMMENDATION_QUERY = gql`
    query JanetRecommendation{
        eventRecommendation {
            id
            name
            link
        }
        certificationRecommendation{
            id
            name
            link
        }
        mentorshipProgramRecommendation{
            id
            name
            link
        }
    }
`

const JanetRecommendations = props => {
    const {data, loading, error} = useQuery(JANET_RECOMMENDATION_QUERY)
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const recommendationSection = (sectionTitle, sectionData) => {
        return sectionData && (
            <Grid item md={6} lg={4} xl={4} sm={12} xs={12} sx={{ paddingTop: '11px !important' }}>
                <Box
                    sx={{
                        borderRadius: '30px',
                        border: `1px solid ${variables.CYAN}`,
                        minHeight: '300px'
                    }}
                >
                    <Box
                        sx={{
                            background: variables.BLUE,
                            py: 2,
                            px: 4,
                            borderTopLeftRadius: '30px',
                            borderTopRightRadius: '30px'
                        }}
                    >
                        <Typography
                            variant='h3'
                            className='info-card-heading font-color-white extrabold'
                        >
                            {sectionTitle}
                        </Typography>
                    </Box>
                    <Box sx={{m: 4}}>
                        {
                            sectionData.map((data) => {
                                return <Box sx={{mb: 4}}>
                                    <Box component='a' href={addHttpsToUrl(data.link)} target='_blank' sx={{textDecoration:'underline'}}><Typography>
                                    {data.name}
                                </Typography>
                                </Box>
                                </Box>
                            })
                        }
                    </Box>
                </Box>
            </Grid>
        )
    }

    if(loading) <Loader/>
    const recommendedEvents = data && data.eventRecommendation
    const recommendedCertification = data && data.certificationRecommendation
    const recommendedPrograms = data && data.mentorshipProgramRecommendation
    return (
        <Layout
            containerSx={{
                mt: {
                    md: 11,
                    lg: 10
                },
                pt: 0
            }}
        >
            <PageMetaTags title='Janet Recommendation | Bunton' />
            <Container maxWidth='1520px' disableGutters>
                <Box
                    sx={{
                        height: {
                            sm: '150px',
                            md: '193px',
                            lg: '350px'
                        }
                    }}
                >
                    <Box
                        component='img'
                        src={
                            ['mob'].includes(responsiveBreakpoint) ? JanetMobileBanner : JanetBanner
                        }
                        sx={{
                            width: '100%',
                            height: '100%',
                            maxHeight: '350px',
                            objectFit: 'cover'
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        backgroundColor: 'rgb(188, 221, 210)',
                        marginLeft: { xs: '24px', sm: '50px', md: '85px', lg: '100px', xl: '215px' },
                        marginRight: { xs: '26px', sm: '50px', md: '83px', lg: '103px', xl: '215px' },
                        marginTop: { xs: '10px', sm: '20px', md: '28px' },
                        marginBottom: { xs: '0px', sm: '0px', md: '45px' },
                        padding: { xs: '17px', sm: '5px', md: '7px' },
                        borderRadius: { xs: '30px', sm: '30px', md: '55px' },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontFamily: "Poppins Regular",
                    }}
                >
                    <Typography sx={{
                        marginTop: {xs: '0px', sm: '16px', md: '0px'},
                        marginBottom: {xs: '0px', sm: '16px', md: '0px'},
                        marginLeft: {xs: '0px', sm: '16px', md: '33px', lg: '18px'},
                        marginRight: {xs: '0px', sm: '16px', md: '0px'},
                        padding: {xs: '0px', sm: '16px', md: '0px'},
                        display: {xs: 'block', sm: 'block', md: 'block'},
                        fontSize: {xs: '14px', sm: '14px', md: '15px'},
                      }}>
                      Wenn sie ein Unternehmen haben mit mind. einer Frau in der Geschäftsführung und hier gerne stehen wollen, dann schreiben Sie uns eine Nachricht an <a href="mailto:welcome@bunton.de" style={{ textDecoration: 'underline' }}>welcome@bunton.de</a>.
                    </Typography>
                </Box>
                <Box sx={{ mt: '35px', mx: { xs: 3, sm: 6, md: 10, lg: 12 } }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Grid container spacing={4} maxWidth='1520px'>
                            {recommendedEvents &&
                                recommendedEvents.length > 0 &&
                                recommendationSection('Events', recommendedEvents)}
                            {recommendedPrograms &&
                                recommendedPrograms.length > 0 &&
                                recommendationSection('Coaching & Mentoring', recommendedPrograms)}
                            {recommendedCertification &&
                                recommendedCertification.length > 0 &&
                                recommendationSection(
                                    'Anbieter Zertifizierungen',
                                    recommendedCertification
                                )}
                        </Grid>
                    </Box>
                    <Box sx={{ mt: 8 }}>
                        <IntrestingJobs />
                    </Box>
                </Box>
            </Container>
        </Layout>
    )
}

export default JanetRecommendations